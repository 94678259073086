// @flow

import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Script from 'react-load-script'
import { VideoModal } from 'shared-components'
import Layout from '../components/Layout'

import './jobs.scss'

type State = { isOpen: boolean }

export default class JobsPage extends Component<{}, State> {
  state = { isOpen: false }

  getJobs = () => {
    window.whr_embed &&
      window.whr_embed(287989, {
        base: 'jobs',
        zoom: 'country',
        grouping: 'none',
      })
  }

  openModal = () => {
    this.setState({ isOpen: true })
  }

  closeModal = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <Layout className="jobs-page">
        <Helmet title="Jobs - Mainframe - Decentralized Application Platform" />
        <Script
          url="https://www.workable.com/assets/embed.js"
          onLoad={this.getJobs}
        />
        <div className="top">
          <div className="background" />
          <div className="container">
            <div className="title">
              <h1>{"Let's build Mainframe Together"}</h1>
              <h2>
                {
                  "We're on the hunt for top-class talent to join our rapidly growing team of proven winners determined to succeed."
                }
              </h2>
            </div>
            <div className="video">
              <p className="video-text">Watch our team video</p>
              <div className="video-thumb" onClick={this.openModal} />
              <VideoModal
                isOpen={this.state.isOpen}
                onClose={this.closeModal}
                videoId="EAWkjEGQoI4"
              />
            </div>
          </div>
        </div>
        <div className="jobs">
          <div className="container">
            <h2>Join us, we are looking for...</h2>
            <div id="whr_embed_hook" />
          </div>
        </div>
      </Layout>
    )
  }
}
